.finance_form

	.form_body

		.form_block

			// ========== Validation Status ==========
			&.invalid
				.form_label_block, .radio_head
					color #ff0600

				.form_input_block
					background-color rgba(255,6,0,.1)
					border 2px solid rgba(255,6,0,1)
					color red
					+placeholder()
						color red

					&:focus
						outline rgba(255,6,0,.4) auto 2px
						border-color rgba(255,6,0,1)
						background-color rgba(255,6,0,.1)

				.form_input_hint
					background linear-gradient(to bottom, #fb6662, #e01c17)
					color #fff

				.radio_block
					label
						color red
					.form_radio:not(:checked) + label:before,
					.form_radio:checked + label:after
						border-color red
						background-color: rgba(255,6,0,.1)

				.checkbox_block
					label, a
						color red
					.form_checkbox:not(:checked) + label:before
						border-color red
					.form_checkbox:checked + label:after
						background red
						border-color rgba(255,6,0,.1)
						
	.gm-err-autocomplete
		background-position: 97% center
		background-size contain
