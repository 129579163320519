/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 26, 2018 */

@font-face {
    font-family: 'solomon_sans_blackregular';
    src: url('../fonts/solomon_sans_black-webfont.woff2') format('woff2'),
         url('../fonts/solomon_sans_black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'solomon_sans_boldbold';
    src: url('../fonts/solomon_sans_bold-webfont.woff2') format('woff2'),
         url('../fonts/solomon_sans_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'solomon_sans_bookregular';
    src: url('../fonts/solomon_sans_book-webfont.woff2') format('woff2'),
         url('../fonts/solomon_sans_book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'solomon_sans_lightregular';
    src: url('../fonts/solomon_sans_light-webfont.woff2') format('woff2'),
         url('../fonts/solomon_sans_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'solomon_sans_normalregular';
    src: url('../fonts/solomon_sans_normal-webfont.woff2') format('woff2'),
         url('../fonts/solomon_sans_normal-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'solomon_sans_semiboldregular';
    src: url('../fonts/solomon_sans_semibold-webfont.woff2') format('woff2'),
         url('../fonts/solomon_sans_semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'solomon_sans_thinregular';
    src: url('../fonts/solomon_sans_thin-webfont.woff2') format('woff2'),
         url('../fonts/solomon_sans_thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
