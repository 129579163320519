.finance_form

	.credit_notification
		text-shadow 1px 1px 1px rgba(0,0,0, .4)

		.credit_info_block
			color #fff
			min-height 93px
			padding-top 20px

		.percent_rate
			text-align center
			font-size 40px
			line-height 40px

		.percent_rate_hint
			display none
			color #fff
			font-size 12px
			line-height 15px
			margin-left 10px

		.probability
			font-size 13px
			line-height 18px
			padding-left 10px
			padding-top 26px

		.credit_hint
			font-size 16px
			line-height 18px
			padding-left 30px
			position relative
			&:after
				content ''
				display none
				position absolute
				top 50%
				margin-top -10px
				left 0
				width 0
				height @width
				border-style solid
				border-width 12px 0 12px 12px
				border-color transparent transparent transparent transparent
		&.green
			background $GREEN

			.credit_hint
				background darken($GREEN, 7%)
				&:after
					display block
					border-color transparent transparent transparent $GREEN

		&.yellow
			background $YELLOW

			.credit_hint
				background darken($YELLOW, 7%)
				&:after
					display block
					border-color transparent transparent transparent $YELLOW


		&.red
			background $RED

			.credit_hint
				background darken($RED, 7%)
				&:after
					display block
					border-color transparent transparent transparent $RED
