
$base_break_point = 450px

.finance_form

	+above($base_break_point)

		.form_line
			clearfix()
			.form_cell_12
				column(1)
			.form_cell_11
				column(11/12)
			.form_cell_10
				column(10/12)
			.form_cell_9
				column(9/12)
			.form_cell_8
				column(8/12)
			.form_cell_7
				column(7/12)
			.form_cell_6
				column(6/12)
			.form_cell_5
				column(5/12)
			.form_cell_4
				column(4/12)
			.form_cell_3
				column(3/12)
			.form_cell_2
				column(2/12)
			.form_cell_1
				column(1/12)



	+below($base_break_point)

		.btn
			display block
			width 100%

		//.slider_notation
		//	margin-left 10px !important

		.label_empty
			display none !important

		.mobile_centered
			text-align center

		//Credit Hint
		.credit_notification
			text-align center
		.credit_info_block, .percent_rate, .credit_hint
			padding 5px !important
			min-height 1px !important
			text-align center
		.credit_hint
			padding 10px 0 !important
			font-size 12px !important
			line-height 15px !important
			&:after
				display none !important
		.probability
			display none

		.percent_rate_hint, .percent_rate
			display inline-block !important
			text-align left
