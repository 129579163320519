.finance_form


	.ui-slider
		position: relative
		text-align: left

	.ui-slider .ui-slider-handle
		position: absolute
		z-index: 2
		width: 1.2em
		height: 1.2em
		cursor: default
		-ms-touch-action: none
		touch-action: none

	.ui-slider .ui-slider-range
		position: absolute
		z-index: 1
		font-size: .7em
		display: block
		border: 0
		background-position: 0 0


	/* support: IE8 - See #6727 */
	.ui-slider.ui-state-disabled .ui-slider-handle,
	.ui-slider.ui-state-disabled .ui-slider-range
		filter: inherit


	.ui-slider-horizontal
		height: .8em

	.ui-slider-horizontal .ui-slider-handle
		top: -.3em
		margin-left: -.6em

	.ui-slider-horizontal .ui-slider-range
		top: 0
		height: 100%

	.ui-slider-horizontal .ui-slider-range-min
		left: 0

	.ui-slider-horizontal .ui-slider-range-max
		right: 0



	.slider_range
		box-shadow 0 0 20px rgba(0, 0, 0, .4)
		border-radius 7px 7px 0 0
		overflow hidden
		position relative
		top -70px

	.slider_head
		background linear-gradient(top, #ffffff, #f2f2f2)
		padding 15px
		border-bottom 2px dashed #dcdcde
		position relative
		.slider_head_title
			font-size 23px
			text-transform uppercase
			color #574869
			text-align center
			position relative
	.label_slider
		position absolute
		top 100px
		right -91px

	.slider_section
		background #fff
		//padding 25px
		border-bottom  2px dashed #dcdcde
		margin-bottom 15px
		padding-bottom 15px

	.slider_row
		padding-right 20px

	.money_slider_wrap
		position relative
		padding-left 20px
		padding-top 20px
		margin-bottom 10px


	.slider_notation
		margin 0 0 10px 0
		font-size 16px
		//margin-left 20%


		#money_box, #term_box
			// font-weight bold
			font-size 19px
			color #574469
			float right
	.amount_btn
		padding 0
		width 30px
		height @width
		line-height 22px
		font-size 15px
		color #fff
		text-shadow 0
		margin-top 38px
		&:focus
			background linear-gradient(top, #ffd728, #f3b100)
			border-color #f79c0d
			box-shadow none
		i.fa
			text-shadow none
			vertical-align baseline
			line-height 30px


	.meter_list
		list-style none
		margin 0
		padding 6px 0 0 0
	.meter_item
		display block
		float left
		width 10%
		height 10px
		position relative
		&:after
			display block
			content ""
			width 2px
			height 8px
			background #e0dfdf
			position absolute
			top 0
			left 50%
			margin-left -1px
	.extreme_points
		color #b7b7b7
		padding-top 6px
		.start_point
			float left
			position relative
			//left -20px
		.end_point
			float right
			position relative
			//right -40px



	/*=======================================================================
	    UI - Widget
	=======================================================================*/
	.slider_section
		//margin-top -20px
		margin-bottom 20px
		padding 20px 0 45px 0
		.ui-widget-header
			background #fed000
			box-shadow inset 0 1px 0 #cba600
			border-radius 50px

		.ui-widget-content
			background #ebedf4
			box-shadow inset 0 1px 1px #bcbec3
			border none
			height 9px
			border-radius 100px

		.ui-state-default,
		.ui-widget-content .ui-state-default,
		.ui-widget-header .ui-state-default
			background #fff
			border 1px solid #e3e5eb
			outline none
			border-radius 100px
			height 30px
			top -10px
			width 30px
			cursor pointer
			box-shadow 0 2px 0px rgba(0, 0, 0, .1)
		.ui-slider-horizontal .ui-slider-handle
			margin-left -15px
		.ui-slider-handle:after
			content ""
			display block
			background #fed000
			border 1px solid #cba600
			width 13px
			height @width
			position absolute
			top 7px
			left 7px
			border-radius 50%
