.finance_form

	.checkbox_block
		padding 10px 0
		text-align center
		/* Remove default checkbox */
		.form_checkbox:not(:checked),
		.form_checkbox:checked {
			position: absolute;
			left: -9999px;
		}
		label
			a
				text-decoration: underline
		.form_checkbox:not(:checked) + label,
		.form_checkbox:checked + label {
			position: relative;
			//overflow: hidden;
			padding-left: 25px;
			cursor: pointer;
			display: inline-block;
			height: 25px;
			line-height: 25px;
			font-weight: 400
			user-select: none;
		}

		/* checkbox aspect */
		.form_checkbox + label:before,
		.form_checkbox + label:after {
			content: '';
			position: absolute;
			left: 0;
			z-index: 1;

			// transition: .2s;
		}
		/* Unchecked styles */
		.form_checkbox:not(:checked) + label:before {
			top: 5px;
			width: 14px; height: 14px;
			border: 1px solid #afb7be;
			border-radius 2px
		}
		.form_checkbox:not(:checked) + label:after {
			top: 5px;
			width: 14px;
			height: 14px;
			border: 1px solid #afb7be;
			z-index: 0;
			border-radius 2px
		}
		/* Checked styles */
		.form_checkbox:checked + label:before {
			content ''
			display: block
			width 10px
			height: 10px
			margin: 8px 0 0 2px;
			background: url($IMG_PATH 'check.svg') center 0 no-repeat
			background-size: cover
			// top: 5px;
			// left: 3px
			// width: 3px;
			// height: 8px;
			// border-top: 3px solid transparent;
			// border-left: 3px solid transparent;
			// border-right: 3px solid #fff;
			// border-bottom: 3px solid #fff;
			// transform: rotateZ(37deg);

			// -webkit-transform-origin: 20% 40%;
			// transform-origin: 100% 100%;
		}
		.form_checkbox:checked + label:after {
			top: 5px;
			width: 14px;
			height: 14px;
			border: 1px solid #afb7be;
			background-color: white;
			z-index: 0;
			border-radius 2px
		}
		/* disabled checkbox */
		.form_checkbox:disabled:not(:checked) + label:before,
		.form_checkbox:disabled:checked + label:before {
			top: 2px;
			box-shadow: none;
			background-color: #444;
			width: 14px; height: 14px;
			border: 3px solid #afb7be;
			transform: rotateZ(0deg);
		}
		.form_checkbox:disabled + label {
			color: #555;
		}
		.form_checkbox:disabled:not(:checked) + label:hover:before {
			// border-color: #444;
		}

		/* hover style just for information */
		.form_checkbox:not(:checked) + label:hover:before {
			// border-color: #666;
		}