.finance_form

	.form_body

		.partners_list
			padding 0 5px

			.partners_item
				background #fff
				padding 20px 20px 10px 20px
				//&:not(:last-child)
				margin-bottom 20px
				border 2px solid #e3e3e3

				.partners_image
					text-align center

					.partners_img
						max-width 100%
						height auto

				.partners_money, .partners_percent
					padding-bottom 10px

					.partners_money_head
						color #999
						font-size 12px
						line-height 1.4
						padding 10px 0
						text-align center

					.partners_money_text
						font-size 14px
						line-height 1.4
						text-align center
						text-transform uppercase

				.partners_go
					padding-top 20px

					.partners_link
						font-weight normal
						font-size 13px
						line-height 18px
						text-transform uppercase
						border-radius 5px
						padding 10px 0


				.partners_hint
					text-align center
					border-top 1px dashed #d1d1d1
					padding-top 10px
					margin-top 10px
					font-size 12px
					line-height 1.4
					color #000




