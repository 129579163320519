
.credit-form
	.agreement_block

		label
			color #a9b3bd
			font-weight 300
			input
				margin-right: 10px
		a
			color #00c464
			font-weight 300
	.mb-20
		margin-bottom: 20px
	.sn_block
		padding-top: 28px
		.radio
			margin 0
	.special_notice
		font-weight 600
		span
			color #a9b3bd
			font-weight 300
	input,
	button
		outline none !important
	.range_holder
		margin-bottom: 22px
	.title-2
		font-size 26px
		line-height 30px
	.title_small
		font-size 14px
		line-height 30px
		display inline-block
		text-align center
		width 100%
	.form-progress
		margin-bottom 16px
	.form-progress-text
		display block
		width 100%
		text-align center
		margin: 0
.form-style
	position: relative
	min-height 55px
	margin-bottom: 25px
	padding-top: 15px

	&:not(.completed)
		.form-label
			font-size: 16px
			color $color4
			transform translateY(23px)
			transition transform .2s, font-size .2s
		.form-input
			font-size: 16px
			&:focus
				& ~ .form-label
					transform translateY(0)
					font-size: 12px
	textarea
		outline: none
		padding-top: 7px
		resize vertical
		min-height 40px
		transition min-height .2s
		&.textarea-height
			min-height 100px


.credit-form
	padding: 50px 80px
	margin 0 auto
	box-shadow 0 20px 30px #dbe1e9
	.form-label
		font-size 12px !important
		font-weight 300
		color #a9b3bd
	input[type="text"]
		border-bottom: 2px solid #a9b3bd
		&:focus,
		&:active
			border-color: #00c464
	.loan-term-label
		font-size 14px
		line-height 18px
		color #a9b3bd
	.btn
		width 100%
		&.btn-primary-revers
			background-color #fff
			color #00c464
			border-color #00c464

	&-control
		.btn
			padding: 11px 30px
	.loan-term-2
		padding-top: 20px
		margin-bottom: 50px
		.btn
			margin-bottom: 8px
			font-size: 16px
	.col
		margin-right: 20px
		&:nth-last-of-type(1)
			margin-right: 0
	&-question
		.btn-1
			padding-top: 12px
			padding-bottom: 12px
			font-size: 16px
		.title-2
			margin-bottom: 30px
		.row
			margin-left: -10px
			margin-right: -10px
			[class*="col"]
				padding-left: 10px
				padding-right: 10px
				margin-bottom: 20px
	&-control
		position: absolute
		bottom 0
		right: 0
		display flex
		justify-content space-between

	&-step
		height 260px

.form-progress
	margin-bottom: 40px
	&-text
		display flex
		justify-content space-between
		align-items center
		margin-bottom: 10px
		font-size: 14px
		color $color4
		&-item
			position: relative
			&:before
				content ''
				position: absolute
				z-index: 1
				left 50%
				top 24px
				width w = 10px
				height 10px
				margin-left: -(w / 2)
				border-radius 50%
				background: $color3
			&:first-child
				&:before
					background: $color2
					left 0
			&:last-child
				&:before
					left auto
					right 0
	&-bar
		position: relative
		height 2px
		background: $color3
		// &:before,
		// &:after
		// 	content ''
		// 	position: absolute
		// 	top 50%
		// 	width 10px
		// 	height h = 10px
		// 	margin-top: -(h / 2)
		// 	border-radius 50%
		// 	background: $color3
		// &:before
		// 	left 0
		// 	background: $color2

		&:after
			right 0
	&-fill
		width 0
		height 2px
		background: $color2



#formCarousel
	padding-bottom: 75px


.form
	&-group
		margin-bottom: 15px
	&-input
		position: relative
		z-index: 1
		width 100%
		height 40px
		border 0
		border-bottom 2px solid $color3
		background: none
		font-size: 16px
	&-label
		margin-bottom: 0
		font-size: 12px
		color $color4
		position: absolute
		left 0
		top 2px

.form-group
	position: relative


.carousel-item
	display none
	&.active
		display block

.success_block
	max-width 550px
	margin auto
	.success_block_title
		font-size 18px
		text-align center
		line-height 1.4
	.success_sign
		display block
		color #fff
		background-color #339e0e
		width 90px
		height 90px
		line-height 90px
		font-size 40px
		text-align center
		border-radius 50%
		margin 25px auto
	.success_block_subtitle
		line-height 1.4
		text-align center
		margin 0 0 50px
		//padding-right 60px
		//background: url('//s3-eu-west-1.amazonaws.com/static.leadia.ru/forms/paydayru/service_arrow.png') 100% 50% no-repeat
		+above(650px)
			font-size 16px
		+below(650px)
			font-size 14px
