$COLOR_1 = #00c464
$COLOR_2 = #d8d8d8
$GREEN = #70cd6a
$YELLOW = #f0c02f
$RED = #e36d37

@import 'nib'
@import '../../../../node_modules/axis/axis/index.styl'
@import '../../../../node_modules/rupture/rupture/index.styl'
@import '../../../../public/finmfo/bower_components/jeet/stylus/jeet/index.styl'

@import '_header.styl'
@import '_mainslider.styl'
@import '_layout.styl'
@import '_feed.styl'
@import '_footer.styl'
@import '_form.styl'
@import '_options.styl'
@import 'font'
@import 'main.styl'

.hidden_block
	display: none

.text_inline
	white-space: nowrap

.finance_form
	.credit_carma_form
		font-size 15px
		padding 30px 0
		.step
			border 0
			background: white
		.money_slider_wrap
			+above(500px)
				padding-top: 30px
				padding-left: 50px
				padding-right: 50px
			+below(500px)
				padding-top: 20px
				padding-left: 20px
				padding-right: 20px
		.slider_notation
			color: #767676
			#money_box,
			#term_box
				color #000
				margin-left: 10px
				font-size 21px
			#money_box
				font-weight 700
		.slider_section
			border-bottom 0
			padding-bottom: 20px
			.ui-widget-header
				background: $COLOR_1
				box-shadow none
			.ui-widget-content
				background: 0
				height: 21px
				box-shadow none
				cursor: pointer
				&:before
					content: ''
					position: absolute
					left: 0
					top: 9px
					width: 100%
					height: 3px
					background: #c2dae6
				.ui-state-default
					top -5px
					height: 30px
					width: 30px
					border-radius: 50%
					background-color $COLOR_1
					cursor pointer
					border 0 none
					&:before
						content '||'
						line-height 28px
						text-align center
						color #fff
						font-weight 400
						display inline-block
						padding-left: 10px
						letter-spacing: 3px
					&:after
						display: none
			.ui-slider-horizontal
				.ui-slider-range
					top 9px
					height: 3px
		.slider_row
			padding-right: 0
		.meter_list
			display: none
		.start_point,
		.end_point
			font-size: 16px
		.form_body
			+above(500px)
				padding-left: 50px
				padding-right: 50px
			+below(500px)
				padding-left: 20px
				padding-right: 20px
			.form_section_head
				color: #333
				margin: 30px 0
				+above(500px)
					font-size: 18px
			.form_label_block
				+above(500px)
					font 18px/1.2 $BASE_FONT
			.form_input_block
				border 0
				border-bottom 2px solid #dadada
				line-height: 1.2
				border-radius 0
				padding-left: 15px
				+above(500px)
					font-size 16px
				+below(500px)
					font-size: 14px
			.form_block
				margin-bottom: 30px
				&.invalid
					.form_input_block
						border 0
						border-bottom 2px solid #d13f19
		.btn_gold
			background: $COLOR_1
			border 0
			text-shadow none
			color: white
			font-size 20px
			min-width 250px
			font-weight: 300
			border-radius: 4px
			@media (max-width 768px)
				padding: 10px 8px
				font-size 14px
				min-width: 0
				width: 100%
				max-width: 250px
				border-radius: 4px
			&:hover,
			&:active,
			&:focus
				background: rgba(0,196,100,0.8)
		.drop_list
			border 0
			border-bottom 2px solid #dadada
		.credit_notification
			background: $COLOR_1
			text-shadow none
			overflow: hidden
			display none
			+above(992px)
				border-radius 10px 10px 0 0
				padding-left: 50px
			+between(500px,992px)
				padding-left: 50px
			+below(500px)
				padding-left: 15px
			+below(450px)
				padding-left: 0
			.percent_rate
				padding-top: 0
			.probability
				line-height: 1.2
				padding-left: 0
				+above(992px)
					font-size: 16px
				+between(500px,992px)
					font-size: 14px
			.credit_hint
				line-height: 1.2
				+above(992px)
					font-size: 20px
				+between(500px,992px)
					font-size: 18px
			.credit_info_block
				+above(450px)
					display flex
					align-items center
				+above(992px)
					min-height 110px
				+between(500px,992px)
					min-height 90px
				&:not(.percent_rate)
					padding-top: 0
		.form_head
			background: none
			padding-top: 0
			border-bottom 0
			+above(500px)
				padding-left: 50px
			+below(992px)
				margin-top: 30px
			.form_header
				+above(650px)
					font-size: 30px
				+below(450px)
					font-size: 20px
			.form_subheader
				+above(650px)
					font-size: 19px
				+below(450px)
					font-size: 16px

.finance_form
	.logo_wrap
		padding-right: 15px
	.feed_container
		margin: 0 auto
	.success_block
		margin: 0 auto
	.title
		text-align center
		// padding: 0 15px
	.feed_item_for_form
		padding: 50px 60px
		border 2px solid #e9eef3
		border-radius: 4px
		margin-bottom: 20px
		transition: .2s;
		position: relative
		@media (max-width 992px)
			padding: 30px
		@media (max-width 767px)
			padding: 20px 15px
	.action_btn
		padding: 13px 12px;
		display: inline-block;
		margin-bottom: 0;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.42857143;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		-ms-touch-action: manipulation
		touch-action: manipulation
		cursor: pointer
		-webkit-user-select: none
		-moz-user-select: none
		-ms-user-select: none
		user-select: none
		background-image: none
		border: 1px solid transparent
		border-radius: 4px;
		color #fff
		text-decoration none
		background-color rgb(0,196,100)
		box-shadow: none
		// @media (max-width 992px)
		// 	margin-top: 14px
		@media (max-width 767px)
			margin-top: 18px
		&:hover,
		&:focus
			text-decoration none
			background-color rgba(0,196,100, 0.8)

@media (max-width 768px)
	.media_tl
		text-align left
		.slider_text_type_large
			text-align left
	.media_tc
		text-align center
		.slider_text_type_large
			text-align center
	.media_tr
		text-align right
		.slider_text_type_large
			text-align right

.finance_form
	.credit_carma_form
		.step[data-step="5"]
			.form_head
				@media (max-width: 992px)
					margin-top: 0
