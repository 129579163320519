.promo-page--1
	background #052824 url(../img/green_pattern.png)
	color #fff

.promo-page--2
	background #bde4e5 url(../img/blue_pattern.png)
	color #000

.promo-page--3
	background #f9c1a0 url(../img/orange_pattern.png)
	color #000

.promo-page--4
	background #fff
	color #000

.promo-feed__header
	text-align center
	padding-top 30px
	margin-bottom 50px

.promo-feed__logo
	display inline-flex
	align-items center
	justify-content center
	position relative
	width 200px
	height auto
	border-radius 50%

	img
		max-width 100%

.promo-feed__logo--big
	width 280px

.promo-feed__banner
	position relative
	background #7C6AD6
	padding 30px
	border-bottom-left-radius 50px
	margin 0 -20px
.promo-feed__gif-banner
	display flex
	justify-content center
	align-items center
	margin-bottom 50px

	img
		max-width 100%
.promo-feed__icon
	width 50px
	height 50px
	margin-bottom 15px

.promo-feed__title
	margin-top 5px
	margin-bottom 20px
	font-size 30px
	font-weight bold

.promo-feed__title--p-6
	width 100%
	line-height 1.2

.promo-feed__subtitle
	font-size 18px

.promo-feed__subtitle--p-6
	font-size 14px
	line-height 1.3

.promo-feed__content
	padding 10px 0 30px

.promo-feed__content--p-6
	padding 50px 0

@media (min-width: 768px)
	.promo-feed__header
		padding-top 50px

	.promo-feed__header--t-left
		text-align left

	.promo-feed__header--t-top
		padding 0

	.promo-feed__banner
		padding 30px 50px 30px 150px
		border-radius 50px
		margin 0

	.promo-feed__icon
		position absolute
		width 65px
		height 65px
		left 4%
		top 25%
		margin 0

	.promo-feed__title
		margin 30px auto 20px
		line-height 1.4

	.promo-feed__title--p-6
		text-align left

	.promo-feed__subtitle--p-6
		text-align left
		font-size 18px

	.promo-feed__content
		padding 50px 0

@media (min-width: 1024px)
	.promo-feed__title
		font-size 46px
		width 860px

	.promo-feed__title--width
		width 1000px

@media (max-width: 1024px)
	.promo-feed__title--p-6
		font-size 25px
