.finance_form

	.drop_list_block
		position relative

		&.active

			.drop_list_wrap
				display block

	.drop_list
		border 2px solid #e3e3e3
		height 40px
		line-height 36px
		padding 0 15px
		border-radius 3px
		background-color #fefefe
		width 100%
		font-size 16px
		color #000000
		position relative
		cursor pointer
		overflow hidden
		span[data-pay]
			display none

		.pay_icon
			position static
			display inline-block
			margin-right 10px
			&.pay_cash_i
				background-position 105px 0
			&.pay_card_i
				background-position -30px 0
			&.pay_qiwi_i
				background-position -75px 0
			&.pay_yad_i
				background-position 15px 0
			&.pay_ps_i
				background-position 60px 0
			&.pay_be_i
				background-position 30px 0
			&.pay_home_i
				background-position 0px 0
			&.pay_mob_i
				background-position 75px 0

		&:after
			display block
			content ""
			width 15px
			height 20px
			position: absolute
			top 50%
			margin-top -10px;
			right 10px
			background-image url("//s3-eu-west-1.amazonaws.com/static.leadia.ru/forms/paydayru/arrowdown.gif")
			background-repeat no-repeat
			background-position 0 0

		&:hover
			background-color #fcfcfc

	.drop_list_wrap
		display none
		//border 2px solid #eae9eb
		border-radius 3px
		padding 10px
		position absolute
		bottom 42px
		right 0
		background #fff
		z-index 1
		width 100%
		min-width 260px
		box-shadow 0 -5px 20px rgba(0,0,0,.3)


	.drop_list_content
		list-style none
		margin 0
		padding 0
		overflow auto

	.drop_list_line
		clearfix()

	.drop_list_item
		//span(1/2)
		transition all .1s ease-in

		//&:nth-child(odd)
		//	border-right 1px solid #eee
		&.form_center
			padding-top 5px
			padding-bottom 5px
			margin-top 10px
			span(1)
			text-align center
			border 0
			min-height auto
			&:hover
				background: none
		&:hover, &.active
			background-color rgba(0, 0, 0, .03)
			color #000
			.pay_icon
				&.pay_cash_i
					background-position 105px 0
				&.pay_card_i
					background-position -30px 0
				&.pay_qiwi_i
					background-position -75px 0
				&.pay_yad_i
					background-position 15px 0
				&.pay_ps_i
					background-position 60px 0
				&.pay_be_i
					background-position 30px 0
				&.pay_home_i
					background-position 0px 0
				&.pay_mob_i
					background-position 75px 0
		&.active
			border-left 3px solid  #329238
			background-color rgba(0, 0, 0, .03)
		.payment_label
			display block
			background transparent
			margin-bottom 1px
			font-size 14px
			padding 10px 10px 10px 35px
			position relative
			line-height 1.2
			border-bottom 1px solid #eee
			cursor pointer
			//min-height 72px
			input[type=checkbox]
				display none


	.pay_icon
		background-image url('//s3-eu-west-1.amazonaws.com/static.leadia.ru/forms/paydayru/pay_sprite.png')
		display block
		position: absolute
		top 10px
		left 8px
		width 15px
		height @width
		&.pay_cash_i
			background-position 105px -45px
		&.pay_card_i
			background-position -30px -45px
		&.pay_qiwi_i
			background-position -75px -45px
		&.pay_yad_i
			background-position 15px -45px
		&.pay_ps_i
			background-position 60px -45px
		&.pay_be_i
			background-position 30px -45px
		&.pay_home_i
			background-position 0px -45px
		&.pay_mob_i
			background-position 75px -45px

