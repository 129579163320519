.finance_form

	.radio_block
		display inline-block
		padding 10px 0
		position relative

		&:not(:last-child)
			margin-right 20px

		/* Remove default checkbox */
		.form_radio:not(:checked),
		.form_radio:checked {
			position: absolute;
			left: -9999px;
		}
		.form_radio:not(:checked) + label,
		.form_radio:checked + label {
			position: relative;
			overflow: hidden;
			padding-left: 30px;
			cursor: pointer;
			display: inline-block;
			height: 25px;
			line-height: 25px;

			user-select: none;
		}

		/* checkbox aspect */
		.form_radio + label:before,
		.form_radio + label:after {
			content: '';
			position: absolute;
			left: 0;
			z-index: 1;

			transition: .01s ease-in;
		}
		/* Unchecked styles */
		.form_radio + label:before {
			top: 2px;
			width: 19px;
			height: 19px;
			border: 1px solid #ddd;
			border-radius 100px
		}
		.form_radio:not(:checked) + label:after {
			top: 2px;
			width: 19px;
			height: 19px;
			border: 1px solid #ddd;
			z-index: 0;
			border-radius 100px
		}
		/* Checked styles */
		.form_radio:checked + label:before {
			top: 2px;
			width: 19px;
			height: 19px;
			border-radius 100px
			background #fff

		}
		.form_radio:checked + label:after {
			top: 2px;
			width: 9px;
			height: 9px;
			z-index: 0;
			border-radius: 100px;
			background-color #00c464;
			left: 5px;
			top: 7px;
			z-index: 3;
		}
		/* disabled checkbox */
		.form_radio:disabled:not(:checked) + label:before,
		.form_radio:disabled:checked + label:before {
			top: 0;
			box-shadow: none;
			background-color: #444;
			width: 19px;
			height: 19px;
			border: 1px solid #444;

		}
		.form_radio:disabled + label {
			color: #555;
		}
		.form_radio:disabled:not(:checked) + label:hover:before {
			border-color: #444;
		}

		/* hover style just for information */
		.form_radio:not(:checked) + label:hover:before {
			border-color: #666;
		}
