.hiw_block
  font-size 16px
  line-height 24px
  padding: 44px 0
  background-color #f6f7f9
  margin-bottom: 84px
  box-shadow: 0px 40px 68px rgba(#2a436d, .27)
  @media (max-width 768px)
    display none
  span
    vertical-align: middle
    line-height 52px
    font-size 18px
    @media (max-width: 767px)
      display block
      width: 100%
      text-align center
      margin-bottom: 20px
  .item
    @media (max-width: 767px)
      text-align center
      margin-bottom: 30px
      &:last-child
        margin: 0
    p,
    img
      display inline-block
      vertical-align top
    p
      margin: 0 0 0 16px
      @media (max-width: 767px)
        margin: 0
        width: 100%
    img
      padding-top: 4px
.widget_container
  margin: 0 0 56px 0
  @media (max-width: 768px)
    width: calc(100% + 30px)
    margin: 0 -15px 56px
body.form_page
  .hero
    .hero_title
      text-align: center
  .main_slider_block
    position: relative
    z-index 99
    .bg_wrap
      box-shadow: 0px 14px 80px rgba(#2f407d, .2)
      padding-top: 0
    .ui-slider-handle
      &:before
        display none
.content_block
  h3
    font-size 18px
    line-height 20px
    margin-bottom: 22px
  p
    font-size 14px
    line-height 20px
    color #a9b3bd
    margin-bottom: 20px
  ul,
  ol
    color #a9b3bd
.content_title
  font-size 30px
  line-height 40px
  font-weight: 400
  margin-bottom: 56px
  margin-top: 0;
  text-transform uppercase
  @media (max-width: 767px)
    font-size 20px
    line-height 26px
    margin-bottom: 28px
.features_block
  margin-bottom: 116px
  .feat_img
    margin-bottom: 24px
    transition: .2s
    width: 132px
    background #fff
    margin: 0 auto 42px
    border-radius: 50%
    @media (max-width 768px)
      margin-bottom: 18px
  a
    text-decoration none
    color #000
    max-width: 314px
    width: 100%
    display inline-block
    background: #f4f4f4
    padding 64px 90px
    border-radius: 4px
    @media (max-width 768px)
      padding: 42px 20px
    &:hover
      color #2174d9
      box-shadow: 0 5px 25px rgba(65,52,77,0.5)
  img
    width: 100%
  > a,
  > div
    text-align center
    @media (max-width: 992px)
      margin-bottom: 50px
      &:nth-child(3),
      &:nth-child(4)
        margin: 0
    @media (max-width: 767px)
      &:nth-child(3)
        margin-bottom: 20px
  span
    display flex
    justify-content center
    align-items center
    height 44px
    width: 100%
    text-align center
    font-size 16px
    color: #23328c
    text-transform uppercase

.main_page_offers
  padding-bottom: 106px
  @media (max-width: 767px)
    padding-bottom: 40px
  .thumbnail_wrap
    background-size cover
    @media (max-width: 992px)
      text-align center !important
      margin-bottom: 20px
      &:last-child
        margin-bottom: 0
    &:first-child
      .type_thumbnail_btn
        background-color #fff
        color #705ebb
        border-color: #705ebb
    &:nth-child(2)
      .type_thumbnail_btn
        background-color #fff
        color #0bbbdb
        border-color: #0bbbdb
    &:nth-child(3)
      .type_thumbnail_btn
        background-color #fff
        color #bf3a92
        border-color: #bf3a92

  .thumbnail
    max-width: 335px
    display inline-block
    text-align center
    padding: 0
    overflow hidden
    transition: .2s
    margin-bottom: 0
    h4
      font-size 18px
      line-height 22px
      font-weight 400
      margin-top: 0
    p
      margin: 0
      font-size 14px
      line-height 22px
      color #a9b3bd
    .header
      text-align center
      padding: 52px 10px 30px
    .body
      padding: 0px 22px 40px
    .footer
      padding: 0 34px 40px
      border: 0 none
      .type_thumbnail_btn
        border-radius: 30px
        padding: 10px 32px
        transition: .2s
    &:hover
      box-shadow: 0px 15px 40px rgba(#183052,0.15)
      transition: .2s
      .footer
        padding: 0 34px 40px
        .type_thumbnail_btn
          transition: .2s
.thumbnail_wrap
  &:first-child
    .thumbnail
      &:hover
        .type_thumbnail_btn
          background-color #705ebb
          border-color: #705ebb
          color #fff
  &:nth-child(2)
    .thumbnail
      &:hover
        .type_thumbnail_btn
          background-color #0bbbdb
          border-color: #0bbbdb
          color #fff
  &:nth-child(3)
    .thumbnail
      &:hover
        .type_thumbnail_btn
          background-color #bf3a92
          border-color: #bf3a92
          color #fff

.slider_info_block
  display -webkit-flex
  display -ms-flex
  display flex
  justify-content space-between
  list-style-type none
  padding: 0
  margin: 0
  @media (max-width 767px)
    justify-content: space-between;
  li
    text-align left
    width: calc(100% / 3)
    @media (max-width 767px)
      width: auto
      margin-bottom: 10px
      text-align left
    &:nth-child(2)
      text-align center
      @media (max-width: 767px)
        text-align right
    &:last-child
      text-align right
      @media (max-width: 767px)
        text-align center
        margin 0
        display none
    .title
      font-size 18px
      color #00c464
      display block
      @media (max-width 767px)
        font-size 14px
    .data
      font-size 14px
      display block
      @media (max-width: 767px)
        font-size 12px

.slider_info_block
  display -webkit-flex
  display -ms-flex
  display flex
  justify-content space-between
  list-style-type none
  padding: 0
  margin: 0
  @media (max-width 767px)
    justify-content: space-between;
  li
    text-align left
    width: calc(100% / 3)
    @media (max-width 767px)
      width: auto
      margin-bottom: 10px
      text-align left
    &:nth-child(2)
      text-align center
      @media (max-width: 767px)
        text-align right
    &:last-child
      text-align right
      @media (max-width: 767px)
        text-align center
        margin 0
        display none
    .title
      font-size 18px
      color #00c464
      display block
      @media (max-width 767px)
        font-size 14px
    .data
      font-size 14px
      display block
      @media (max-width: 767px)
        font-size 12px

.date_range
  margin: 0
  padding: 0
  list-style-type none
  margin-top: -6px
  margin-left: -30px
  @media (max-width: 767px)
    padding-top: 20px
    width: calc(100% + 40px);
    margin: 0 -20px;
  li
    float left
    margin-left: 10px
    width: calc(100% / 4 - 9px)
    &:first-child
      margin: 0;
    @media (max-width: 767px)
      width: calc(100% / 2 - 20px);
      margin:0 10px 20px
      &:first-child
      &:last-child
        margin:0 10px 20px
      &:last-child,
      &:nth-child(3)
        margin-bottom 0
  label
    position: relative;
    display inline-block
    margin 0
    width: 100%
    @media (max-width: 767px)
      width: 100%
    input
      position: absolute;
      left: 0
      top: 0
      width: 100%
      visibility: hidden;
      z-index 33
      margin 0
      height 100%
      + span
        background-color #e9eef3
        display inline-block
        height 100%
        padding: 15px 22px
        border-radius: 4px
        color: #a9b3bd
        cursor pointer
        font-weight 400
        width 100%
        text-align center
        @media (max-width: 767px)
          padding: 10px 12px
      &:hover
        + span
          background-color rgba(#e9eef3, .8)
      &:checked
        + span
            background-color #00c464
            color #fff
.container
  &.slider_container
    @media (max-width: 768px)
      margin-bottom: 54px

.slider_bg_wrap
  background-color #f4f4f4
  margin-bottom: 56px
  .main_slider_block
    margin: 54px auto
    @media (max-width 767px)
      margin: 24px auto
  .inside_container,
  input
    background-color #f4f4f4

.c-table
	&-header
		.c-table-row
			height auto
			border 0
			margin-bottom: 8px
			padding-top: 0
			padding-bottom: 0
			&:hover
				background transparent
		.c-table-col
			font-size: 12px !important
			font-weight: 300
			text-transform: uppercase
			color #adb6bf
			&:nth-of-type(1)
				+above(651px)
					flex 1.4
				+below(650px)
					flex 1

	&-body
		background: white
	&-row
		position: relative
		display flex
		align-items center
		border-top 1px solid $color2
		transition background .2s
		+above(651px)
			height 100px
			padding 0 30px 0 20px
		+below(650px)
			padding 0
			height 70px
		+below(550px)
			height auto
			padding-top: 20px
			padding-bottom: 84px
		&:hover
			background: #f9fafb
	&-col
		display flex
		align-items center
		flex 1
		+below(550px)
			padding-right: 10px
			font-size: 13px
		&:nth-of-type(1)
			+above(651px)
				flex 1.4
				font-size: 16px
			+below(650px)
				flex 1
				font-size: 14px
			font-weight: 500
			+above(769px)
				min-width 240px
			img
				width 120px
				margin-right: 20px
				+below(768px)
					display: none
		&:nth-of-type(2)
			+above(1000px)
				flex 1.2
			+below(1000px)
				flex 1
		&:nth-last-of-type(1)
			justify-content flex-end
			flex 0
			+above(651px)
				min-width 170px
			+below(650px)
				min-width 124px
			+below(550px)
				position absolute
				left 0
				bottom 20px
				width 100%
		&:nth-last-of-type(2)
			padding-left: 20px


	&-footer
		display: block
		text-align: center
		padding: 15px 0
		border-top 1px solid $color2
		border-bottom 1px solid $color2
		cursor: pointer
		color black
		&:hover
			color black
			text-decoration: underline
	.btn-1
		font-size: 14px
		+above(769px)
			min-width 170px
		+below(768px)
			padding-left: 10px
			padding-right: 10px
			min-width auto
		+below(550px)
			width 100%
.c-table
  .btn-1
    background-color #00c464
    color #fff
    padding: 14px 6px
    &:hover
      background-color rgba(#00c464, .8)
  .c-table-footer,
  .c-table-row
    border-top 1px solid #d8d8d8
  .c-table-header
    .c-table-row
      border 0 none

.link_column
	.aside_element
		margin-left: 30px
		width calc(100% / 4 - 30px)
		float left
		margin-bottom 30px
		@media (max-width 768px)
			width: 100%
			margin-left: 0
			margin-bottom: 18px
		&:first-child,
		&:nth-child(5n)
			margin-left: 0
	.aside_element_title
		font-size: 16px
		font-weight 600
		padding-bottom: 12px
	ul
		list-style-type none
		padding: 0
		margin: 0
		li
			display inline-block
			width: 100%
			font-size 14px
			line-height 18px
			font-weight 400
			padding-bottom: 2px
			a
				text-decoration none
				&:hover,
				&:focus,
				&:active
					text-decoration none
					color #000

.slider_internal_pages
  .media_hide
    display block
  .media_hide_flex
    display -webkit-flex
    display -ms-flex
    display flex
  .media_show
    display none
  @media (max-width 767px)
    .title_block.text-center .hero_title
      text-align left
    .main_slider_block .slide .right .slider_text_type_medium
      padding-right: 10px
    .main_slider_block
      .slide
        background-color #fff
        .inside_container
          padding: 26px 24px
          input
            background-color #fff
    .hero_tail
      display none
    .main_slider_block
      .slide
        padding: 0
        .left
          padding-right: 10px
          .slider_text_type_medium
            font-weight 600
        .right
          padding-left: 0
          .slider_text_type_medium
            font-size 24px
            line-height 26px
            font-weight 600
            top -4px
    .title_block,
    .title_block .subtitle,
    .title_block .hero_title + .subtitle
      text-align left !important
    .title_block .subtitle,
    .title_block.text-center .subtitle
      font-size 14px
      line-height 20px
    .title_block
      max-width: 270px
      .hero_title
        line-height 34px
        font-weight 600
    .media_hide,
    .media_hide_flex
      display none
    .media_show
      display block
    .main_slider_block
      margin: 0 0 -20px
      box-shadow: 0px 1px 2px rgba(#3e3f41, .1),
                  0px 2px 4px rgba(#3e3f41, .1),
                  0px 4px 8px rgba(#3e3f41, .1),
                  0px 8px 16px rgba(#3e3f41, .1),
                  0px 16px 32px rgba(#3e3f41, .1)
      #slider
        max-width: 100%
        margin: 10px auto 40px
      .bg_wrap
        padding: 0
    .media_slider_info
      .scroll_btn
        margin-bottom: 30px
        font-size 16px
        font-weight 600
        width: 100%
      span
        display block
        font-size 12px
        line-height 20px
        color #999999

@media (max-width 320px)
  .slider_internal_pages .main_slider_block .slide .right .slider_text_type_medium
    font-size 22px

.login_container
  max-width 470px
  margin: 0 auto 62px
  .ta-center
    text-align center
  .form-control
    height 48px
  .dib
    display inline-block
  .btn
    padding: 13px 16px
    margin-bottom: 20px

.banner_item
	background-color #45b2f4
	color #fff
	text-decoration none
	.text_side
		width: calc(100% - 238px)
		color #fff
		@media (max-width 600px)
			padding: 12px 0 0
			width: calc(100% - 58px)
	.img_block
		padding: 0 20px
		@media (max-width 600px)
			padding: 0 0 0 10px
	a
		display -webkit-flex
		display -ms-flex
		display flex
		align-items: center;
		&:hover,
		&:focus,
		&:active
			text-decoration none
			color #fff
	b
		font-size 22px
		line-height 30px
		@media (max-width 600px)
			display block
			margin-bottom: 10px
			font-size 14px
			line-height 18px
	p
		font-size 16px
		line-height 30px
		margin: 0 0 14px
		color #d0e8f9
		@media (max-width 600px)
			margin-bottom: 10px
			font-size 12px
			line-height 18px
	span
		font-size 14px
		line-height 24px
		display inline-block
		font-weight 600
		display -webkit-flex
		display -ms-flex
		display flex
		align-items: center
		@media (max-width 600px)
			font-size 12px
			line-height 18px
		svg
			padding-left: 10px
			width: 33px

.share_toolbox
	.at-svc-facebook
		display none!important
.feed_item
  &.banner_item
    background-color #45b2f4
    color #fff
    text-decoration none
    @media (max-width 600px)
      border 0 none
    .text_side
      width: calc(100% - 238px)
      color #fff
      @media (max-width 600px)
        padding: 12px 0 0
        width: calc(100% - 58px)
      @media (max-width 425px)
        width: calc(100% + 82px)
        padding: 0
    .img_block
      padding: 0 20px
      @media (max-width 600px)
        padding: 0 0 0 8px
        img
          width: 100%
    a
      display -webkit-flex
      display -ms-flex
      display flex
      align-items: center;
      &:hover,
      &:focus,
      &:active
        text-decoration none
        color #fff
    .banner_heading
      font-size 22px
      line-height 30px
      @media (max-width 600px)
        display block
        margin-bottom: 10px
        font-size 14px
        line-height 18px
        white-space: normal;
    .banner_paragraph
      font-size 16px
      line-height 30px
      margin: 0 0 14px
      color #d0e8f9
      @media (max-width 600px)
        margin-bottom: 10px
        font-size 12px
        line-height 18px
        font-weight 600
    .banner_span
      font-size 14px
      line-height 24px
      font-weight 600
      display -webkit-flex
      display -ms-flex
      display flex
      align-items: center
      color #fff
      @media (max-width 600px)
        font-size 12px
        line-height 18px
      svg
        padding-left: 10px
        width: 33px
  &.feed_rating
    display: flex
    align-items: center
    padding: 30px
    h2
      margin-top: 0
      font-size: 30px
      +below(767px)
        font-size: 24px
    p
      margin-bottom: 20px
    img
      display: block
      margin-right: 30px
      max-width: 140px
    .btn
      padding: 13px 22px
      +below(767px)
        width: 100%
    .feed_rating_info
      width: calc(100% - 170px)
      +below(767px)
        width: 100%
        text-align: center
    +below(767px)
      flex-direction: column
      padding: 20px 15px
      p
        margin-bottom: 0
      img
        margin-right: 0
        margin-bottom: 20px
