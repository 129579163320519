body
  font-family: 'solomon_sans_normalregular', sans-serif, '-apple-system';
  font-weight 400
html
  &.active_menu
    overflow-x: hidden;
.tooltip_block
  position: absolute;
  left 35%
  top -20px
  width: 20px
  height 20px
  text-align center
  cursor: pointer;
  @media (max-width: 768px)
    display none
  p
    position: absolute;
    visibility: hidden;
    z-index -1
    background #fff
    box-shadow: 0 5px 18px rgba(42,67,109,0.27)
    padding: 16px
    border-radius: 8px
    transform: translateY(-100%);
    width: 280px
    height 90px
    top 0
    text-align left
  &:hover
    display block
    p
      visibility: visible;
      z-index 22
.mw_five
  max-width: 500px
.btn-primary
  background-color #00c464
  border-color: #00c464
  font-size 16px
  @media (max-width: 768px)
    font-size 14px
    padding: 8px 12px !important
  &:hover,
  &:focus,
  &:visited,
  &:active
    background-color #00c464
    border-color: #00c464
.xs_hidden_ovf
  @media (max-width: 992px)
    overflow-x hidden
  &.media_menu
    overflow: hidden;
    &.is_active
      overflow visible
.width_full
  width: 100%

.title_block
  margin-bottom: 36px
  .hero_title
    margin-bottom: 0
    + .subtitle
        color #23328c
        text-align left
  &.text-center
    .subtitle
      line-height 28px
    .hero_title
      @media (max-width 767px)
        font-size 20px
        line-height 32px
    // .hero_title,
    // .subtitle
    //   text-align center
  .content_title
    margin-bottom: 0px
  .subtitle
    font-size 18px
    line-height 40px
    display inline-block
    width: 100%
    text-align center
    @media (max-width: 768px)
      padding-top: 8px
      font-size 14px
      line-height 22px
