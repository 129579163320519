.finance_form

	.form_body

		.progress_lead
			//background-color #fff
			padding 40px 0

		.progress_lead_head
			font-size 20px
			text-align center
			margin-bottom 5px

		.progress_lead_subhead
			font-size 18px
			text-align center
			margin-bottom 40px

		.progress_container
			text-align center

		input.dial
			color #525255 !important
			font-weight normal !important
			font-size 32px !important