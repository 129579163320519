body
  &.main_page
    .hero
      margin-bottom: 0
  &.front_page
    header
      margin-bottom: 12px
  &.content_pages
    .hero
      // background red
      background rgba(#00c464, .4)
.error_no_page
  text-align left
  color #fff
  .fof_block
    font-size 100px
    line-height 102px
    font-weight: 700
    margin-bottom: 10px
  span
    font-size 26px
    line-height 30px
    font-weight: 700
    margin-bottom: 10px
    display inline-block
  p
    font-size 14px
    line-height 16px
    margin-bottom: 0
.burger
  position: absolute;
  padding: 8px
  right 7px
  top 5px
  cursor pointer
  display none
  @media(max-width: 992px)
    display block
    z-index 99
  span
    width: 20px
    height 2px
    background-color #fff
    display block
    margin-bottom: 3px
    &:last-child
      margin: 0
.header_adv_block
  margin-bottom: 60px
  @media (max-width 767px)
    display none
  ul
    list-style-type none
    padding: 0
    margin: 0
    li
      position: relative;
      padding-left: 30px
      margin-bottom: 20px
      color #23328c
      font-size 18px
      line-height 24px
      &:last-child
        margin: 0
      &:before
        content ''
        position: absolute;
        left: 0px
        top: 2px
        width: 20px
        height: 20px
        background url('../img/round-check.png') no-repeat;

.hero
  background: #00c464 url('../img/bg.jpg') 50% 0 no-repeat;
  background-size: cover
  margin-bottom: 60px
  @media (max-width: 992px)
    margin-bottom: 54px
  @media (max-width: 768px)
    margin-bottom: 50px
  &.no_slider
    min-height: 400px
  @media (max-width 767px)
    background: #00c464 url('../img/bg.jpg') 56% 0 no-repeat;


.hero_tail
  background: transparent url('../img/round_bg.png') 50% no-repeat;
  background-size: cover
  height: 54px
  @media (max-width 767px)
    display none
header
  padding: 28px 0
  margin-bottom: 62px
  @media (max-width: 992px)
    margin-bottom: 22px
  .container
    position: relative;
  .logo
    display inline-block
    padding: 7px 0
  @media (max-width: 767px)
    padding: 10px 0

.hero_title
  font-size 40px
  line-height 50px
  font-weight 400
  color #23328c
  text-align left
  margin: 0
  margin-bottom: 56px
  text-transform uppercase
  @media (max-width: 992px)
    font-size 30px
    line-height 40px
  @media (max-width: 767px)
    font-size 20px
    line-height 32px

.nav-container
  padding-top: 4px
  text-align right
  overflow: hidden;
  visibility: visible;
  @media (max-width: 992px)
    position: absolute;
    right 15px
    top: 50px
    visibility: hidden;
    z-index -99
    transform: translateX(100%);
    background-color #fff
    transition: .2s
    border-radius: 4px 0 0 4px
    box-shadow: 1px 1px 12px rgba(0,0,0, 0.4)
    &.is_active
      visibility: visible;
      z-index 1
      transform: translateX(16px);
      transition: .2s
      z-index 999
.nav_listing
  list-style-type none
  margin: 0
  padding 0
  display inline-block
  z-index 1
  li
    float: left
    @media (max-width: 992px)
      float none
      display block
  a
    color #23328c
    text-decoration none
    display inline-block
    padding: 4px 16px
    font-size 14px
    position: relative;
    @media (max-width: 992px)
      color #00c464
      width: 100%
      padding: 14px 16px
      font-weight 400
    &:hover,
    &:focus,
    &:visited,
    &:active
      color #23328c
      text-decoration none
      &:after
        content ''
        position: absolute;
        width: calc(100% - 32px)
        left 16px
        bottom -4px
        height 2px
        background-color #00c464
      @media (max-width: 992px)
        color #00c464
        &:after
          display none

.logos_block
  margin-bottom: 84px
  margin-left: -16px
  @media (max-width: 992px)
    display none
  ul
    list-style-type none
    margin 0 auto
    padding 0
    width: 100%;
    li
      float left
      padding: 0 16px

.internal_page
  .hero_title,
  .subtitle
    text-align center
  .title_block
    .hero_title
      text-align center
      + .subtitle
          text-align center
  .logos_block
    text-align center
    ul
      display inline-block
      width: auto
