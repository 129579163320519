.feed_container
  max-width: 940px
  margin 0 auto 60px
.feed_item,
.feed_item_for_form
  padding: 50px 60px
  border 2px solid #e9eef3
  border-radius: 4px
  margin-bottom: 20px
  transition: .2s;
  position: relative;
  &:hover
    box-shadow: 0 0 20px rgba(47,64,125,0.15);
    transition: .2s;
  &:last-child
    margin-bottom: 0
  .media_pt
    @media (max-width 768px)
      padding-top: 40px
  @media (max-width: 992px)
    padding: 30px
  @media (max-width: 767px)
    padding: 20px 15px
    .media_tl
      text-align left
    .media_tc
      text-align center
    .media_tr
      text-align right
  .feed_head
    .logo_wrap
      max-width: 120px
      width: 100%
    img
      width: 100%
    @media (max-width 767px)
      position: absolute;
      top 20px
      right: 0px
      width: 100%
      max-width: 120px
  .feed_head
    @media (max-width: 767px)
      >div
        float left
        width: calc(100% / 2)
        &:last-child
          text-align right
  .title
    font-size 18px
    font-weight 600
    color #2174d9
    display inline-block
    margin-bottom: 20px
    max-width 500px
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media (max-width: 767px)
      margin: 0
      max-width: 150px
  span
    display block
    font-size 12px
    line-height 22px
    color #afb9c2
    @media (max-width: 767px)
      font-size 10px
      line-height 16px
  i,
  b
    font-size 16px
    line-height 22px
    font-style: normal
    white-space: nowrap;
    @media (max-width: 768px)
      font-size 14px
      line-height 18px
    @media (max-width 430px)
      font-size 14px
      white-space: nowrap;
  b
    font-family 'solomon_sans_boldbold', '-apple-system'
  .feed_item_for_form
    @media (max-width 992px)
      i,
      b
        font-size 14px
  .action_btn
    padding: 13px 12px
    @media (max-width: 992px)
      font-size 14px
    @media (max-width 767px)
      margin-top: 18px
  .custom_padding
    padding-top: 40px
    @media (max-width: 767px)
      padding-top: 16px

// .feed_item .media_pt,
// .feed_item_for_form .media_pt
//   padding-top: 40px;

// .feed_item .feed_head,
// .feed_item_for_form .feed_head
//   @media (max-width 768px)
//     position: absolute;
//     top: 20px;
//     right: 20px;
//     width: 100%;
//     max-width: 120px;

// .feed_item,
// .feed_item_for_form
//   position: relative;
