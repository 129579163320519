.footer
  padding: 50px 0
  border-top 1px solid #e3e3e3
  .footer-menu
    a
      display block
	&-item
		&:not(:last-child)
			+below(620px)
				margin-bottom: 20px
		&:nth-of-type(1)
			+above(620px)
				width 30%
		&:nth-last-of-type(1)
			+above(620px)
				width 40%
	&-menu
		a
			display: block
			margin-bottom: 5px
.social
	margin: 10px 0 20px
	a
		text-decoration: none
		display: inline-block;
		width: 34px;
		height: 34px;
		line-height: 32px;
		text-align: center;
		margin-right: 8px;
		font-size: 15px
		transition transform .2s
		color black
		opacity .6
		cursor: pointer
		&:hover
			transform scale(1.2)
