.animated
	animation-duration .1s
	animation-fill-mode both

.finance_form

	@keyframes slideInUp {
		from {
			transform: translate3d(0, 100%, 0);
			visibility: visible;
		}

		to {
			transform: translate3d(0, 0, 0);
		}
	}

	@keyframes slideOutDown {
		from {
			opacity: 1
			transform: translate3d(0, 0, 0);
		}

		to {
			opacity 0
			visibility: hidden;
			transform: translate3d(0, 100%, 0);
		}
	}

	.form_input_hint
		@extends .animated
		animation-name slideInUp



	.slideOutDown
		@extends .animated
		animation-name: slideOutDown


